<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div>
    <Filtros
      v-if="!isLoading"
      :user="user"
      :destinos="destinos"
      :tipos-destino="tiposDestino"
      :categories="categories"
      :all-operadores="allOperadores"
      :all-marcas-list="allMarcas"
      @refresh="refresh"
    />
    <v-card-text v-if="!isLoading">
      <v-row>
        <v-col
          v-if="permisos.includes('hotel:create') && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            @click="newItem"
          >
            <v-icon>{{ icons.mdiPlus }}</v-icon>
            <span>{{ $t('btn.insert') }}</span>
          </v-btn>
        </v-col>

        <v-col
          v-if="permisos.includes('hotel:create') && !offline"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="uploadLote"
          >
            <v-icon>{{ icons.mdiCloudUpload }}</v-icon>
            <span class="pl-2">{{ $t('btn.uploadLote') }}</span>
          </v-btn>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          v-if="user.nivel === -1 && permisos.includes('exportation:list')"
          cols="12"
          md="2"
        >
          <v-btn
            color="primary"
            class="mb-4 me-3"
            outlined
            @click="exportar"
          >
            <v-icon>mdi-export</v-icon>
            <span class="pl-2">{{ $t('menu.export') }}</span>
          </v-btn>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-text-field
            v-model="itemsPerPage"
            :label="$t('lbl.itemsPerPage')"
            type="number"
            min="5"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="isLoading">
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>

        <v-spacer></v-spacer>

        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="button"
          ></v-skeleton-loader>
        </v-col>

        <v-col
          cols="12"
          md="2"
        >
          <v-skeleton-loader
            class="mx-auto"
            type="text"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-card-text>

    <!--<v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingDestinos"
            v-model="model.destino"
            :items="itemsDestino"
            :search-input.sync="searchDestino"
            hide-details
            hide-selected
            :label="$t('lbl.destino')"
            outlined
            dense
            clearable
            @change="load()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('lbl.destino') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item }}</v-list-item-title>
                <v-list-item-subtitle></v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingTipoDestinos"
            v-model="model.tipo_destino_id"
            :items="tiposDestino"
            item-text="name"
            item-value="id"
            :label="$t('menu.typeDestination')"
            outlined
            dense
            hide-details
            clearable
            @change="load()"
          ></v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-select
            v-if="!isLoadingCategories"
            v-model="model.categoria_id"
            :items="categories"
            item-text="name"
            item-value="id"
            :label="$t('menu.category')"
            outlined
            dense
            hide-details
            clearable
            @change="load()"
          >
            <template v-slot:selection="{ item }">
              <span v-if="item.name !== null">
                {{ item.name }}
                <span v-if="item.plus">PLUS</span>
              </span>
              <span v-if="item.name === null">
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-rating
                      v-model="item.cant_estrellas"
                      color="primary"
                      size="30"
                      readonly
                    ></v-rating>
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    class="mt-2"
                  >
                    <span v-if="item.plus">PLUS</span>
                  </v-col>
                </v-row>
              </span>
            </template>

            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title v-if="item.name !== null">
                  {{ item.name }}
                  <span v-if="item.plus">PLUS</span>
                </v-list-item-title>
                <v-list-item-title v-if="item.name === null">
                  <v-row>
                    <v-col
                      cols="12"
                      md="8"
                    >
                      <v-rating
                        v-model="item.cant_estrellas"
                        color="primary"
                        readonly
                        size="30"
                      ></v-rating>
                    </v-col>
                    <v-col
                      cols="12"
                      md="2"
                    >
                      <p
                        v-if="item.plus"
                        class="pt-3"
                      >
                        PLUS
                      </p>
                    </v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-select>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingOperadors"
            v-model="model.operador_id"
            :items="itemsOperadores"
            :search-input.sync="searchOperador"
            hide-details
            hide-selected
            :label="$t('menu.operador')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @click:clear="getMarcasAsociadas()"
            @change="
              setOperadores()
              load()
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.operador') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-if="!isLoadingMarcas"
            v-model="model.marca_asociada_id"
            :items="itemsMarcas"
            :search-input.sync="searchMarcas"
            hide-details
            hide-selected
            :label="$t('menu.marcaAsociada')"
            outlined
            dense
            item-text="name"
            item-value="id"
            clearable
            @change="load()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title>
                  {{ $t('menu.marcaAsociada') }}
                </v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <span v-text="item.name"></span>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-if="!isLoadingMarcas"
            v-model="model.name"
            :label="$t('lbl.hotel')"
            hide-details
            dense
            outlined
            @input="load()"
          ></v-text-field>
          <v-skeleton-loader
            v-else
            class="mx-auto"
            type="card-heading"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-form>-->
    <v-divider></v-divider>
    <v-form class="multi-col-validation px-5 py-5">
      <template v-if="!isLoadingFilters">
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <v-btn
              v-if="
                model.destino ||
                  model.tipo_destino_id ||
                  model.categoria_id ||
                  model.operador_id ||
                  model.marca_asociada_id ||
                  model.name ||
                  model.not_public ||
                  model.sin_operador ||
                  model.sin_marca ||
                  model.sin_fotos ||
                  model.sin_tag_fotos ||
                  model.sin_tag_principal ||
                  model.sin_direccion ||
                  model.sin_localidad ||
                  model.sin_crop ||
                  model.sin_contact
              "
              class="mx-2"
              fab
              outlined
              small
              color="error"
              @click="clearSearch()"
            >
              <v-icon small>
                {{ icons.mdiEraserVariant }}
              </v-icon>
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="text-left text-lowercase"
          >
            <v-btn
              v-if="totalNotPublic > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.not_public ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.not_public ? 'error' : ''"
              @click="changeNotPublic()"
            >
              {{ totalNotPublic }} {{ $t('lbl.notPublic') }}
            </v-btn>
          </v-col>
          <v-col
            v-if="totalInfoPendiente > 0"
            cols="12"
            md="2"
            class="text-left text-lowercase"
          >
            <v-btn
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="showInfoPendient ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!showInfoPendient ? 'error' : ''"
              @click="infoPendient"
            >
              {{ totalInfoPendiente }} {{ $t('lbl.infoPendiente') }}
            </v-btn>
          </v-col>
          <v-spacer></v-spacer>
          <!--<v-col
            cols="12"
            md="9"
            class="text-center"
          >
            <v-btn
              :loading="loading"
              color="primary"
              @click="load()"
            >
              <v-icon
                right
                dark
              >
                {{ icons.mdiMagnify }}
              </v-icon>
              <span class="pl-5">{{ $t('btn.search') }}</span>
            </v-btn>
          </v-col>-->
          <v-col
            v-if="!isLoadingInfo"
            cols="12"
            md="2"
            class="text-lowercase"
          >
            {{ totalF }} {{ $t('lbl.de') }} {{ totalItems }} {{ $t('menu.hotels') }}
          </v-col>
          <v-col
            v-if="isLoadingInfo"
            cols="12"
            md="4"
          >
            <v-progress-circular
              :size="20"
              indeterminate
              color="primary"
              class="mr-2"
            ></v-progress-circular>
            {{ $t('lbl.loadingHotelProduct') }}
          </v-col>
        </v-row>

        <v-row v-if="showInfoPendient">
          <v-col
            cols="12"
            md="12"
            class="text-left text-lowercase"
          >
            <v-btn
              v-if="totalSinOperador > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_operador ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_operador ? 'error' : ''"
              @click="changeSinOperador()"
            >
              {{ totalSinOperador }} {{ $t('lbl.sinOperador') }}
            </v-btn>

            <v-btn
              v-if="totalSinMarca > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_marca ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_marca ? 'error' : ''"
              @click="changeSinMarca()"
            >
              {{ totalSinMarca }} {{ $t('lbl.sinMarcaAsociada') }}
            </v-btn>

            <v-btn
              v-if="totalSinFotos > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_fotos ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_fotos ? 'error' : ''"
              @click="changeSinFotos()"
            >
              {{ totalSinFotos }} {{ $t('lbl.sinFotos') }}
            </v-btn>

            <v-btn
              v-if="totalSinTagFotos > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_tag_fotos ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_tag_fotos ? 'error' : ''"
              @click="changeSinTagFotos()"
            >
              {{ totalSinTagFotos }} {{ $t('lbl.sinTagFotos') }}
            </v-btn>

            <v-btn
              v-if="totalSinTagPrincipal > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_tag_principal ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_tag_principal ? 'error' : ''"
              @click="changeSinTagPrincipal()"
            >
              {{ totalSinTagPrincipal }} {{ $t('lbl.sinTagPrincipal') }}
            </v-btn>

            <v-btn
              v-if="totalSinDireccion > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_direccion ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_direccion ? 'error' : ''"
              @click="changeSinDireccion()"
            >
              {{ totalSinDireccion }} {{ $t('lbl.sinDireccion') }}
            </v-btn>

            <v-btn
              v-if="totalSinLocalidad > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_localidad ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_localidad ? 'error' : ''"
              @click="changeSinLocalidad()"
            >
              {{ totalSinLocalidad }} {{ $t('lbl.sinLocalidas') }}
            </v-btn>

            <v-btn
              v-if="totalSinCrop > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_crop ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_crop ? 'error' : ''"
              @click="changeSinCrop()"
            >
              {{ totalSinCrop }} {{ $t('lbl.sinCrop') }}
            </v-btn>

            <v-btn
              v-if="totalSinContact > 0"
              class="mx-2 text-lowercase"
              outlined
              rounded
              small
              :style="model.sin_contact ? 'background-color: red; color: #FFFFFF;' : ''"
              :color="!model.sin_contact ? 'error' : ''"
              @click="changeSinContact()"
            >
              {{ totalSinContact }} {{ $t('lbl.sinContact') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <template v-if="isLoadingFilters">
        <v-row>
          <v-col
            cols="12"
            md="1"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="button"
            ></v-skeleton-loader>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="text-left text-lowercase"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="button"
            ></v-skeleton-loader>
          </v-col>
          <v-spacer></v-spacer>
          <v-col
            cols="12"
            md="2"
          >
            <v-skeleton-loader
              class="mx-auto"
              type="text"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </template>
    </v-form>

    <template v-if="!isLoading">
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <!--<th class="text-uppercase">
                <span
                  v-if="orderByName === null"
                  style="cursor: pointer;"
                  @click="orderBy('name')"
                >
                  {{ $t('lbl.car') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('name')"
                >
                  {{ $t('lbl.car') }}
                  <v-icon
                    small
                    right
                    :color="orderByName === null ? '' : 'primary'"
                  >
                    {{ orderByName !== null ? (orderByName ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th
                width="15%"
                class="text-uppercase"
              >
                <span
                  v-if="orderByMarca === null"
                  style="cursor: pointer;"
                  @click="orderBy('marca')"
                >
                  {{ $t('menu.marca') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('marca')"
                >
                  {{ $t('menu.marca') }}
                  <v-icon
                    small
                    right
                    :color="orderByMarca === null ? '' : 'primary'"
                  >
                    {{ orderByMarca !== null ? (orderByMarca ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <span
                  v-if="orderByModelo === null"
                  style="cursor: pointer;"
                  @click="orderBy('modelo')"
                >
                  {{ $t('menu.modelo') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('modelo')"
                >
                  {{ $t('menu.modelo') }}
                  <v-icon
                    small
                    right
                    :color="orderByModelo === null ? '' : 'primary'"
                  >
                    {{ orderByModelo !== null ? (orderByModelo ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <span
                  v-if="orderByTransmision === null"
                  style="cursor: pointer;"
                  @click="orderBy('trasnmision')"
                >
                  {{ $t('menu.trasnmision') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('trasnmision')"
                >
                  {{ $t('menu.trasnmision') }}
                  <v-icon
                    small
                    right
                    :color="orderByTransmision === null ? '' : 'primary'"
                  >
                    {{ orderByTransmision !== null ? (orderByTransmision ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <span
                  v-if="orderByTypeAuto === null"
                  style="cursor: pointer;"
                  @click="orderBy('typeCar')"
                >
                  {{ $t('menu.typeCar') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('typeCar')"
                >
                  {{ $t('menu.typeCar') }}
                  <v-icon
                    small
                    right
                    :color="orderByTypeAuto === null ? '' : 'primary'"
                  >
                    {{ orderByTypeAuto !== null ? (orderByTypeAuto ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>
              <th class="text-uppercase">
                <span
                  v-if="orderByCombusible === null"
                  style="cursor: pointer;"
                  @click="orderBy('combustible')"
                >
                  {{ $t('menu.combustible') }}
                </span>
                <v-btn
                  v-else
                  style="background-color: #FAFAFA;"
                  @click="orderBy('combustible')"
                >
                  {{ $t('menu.combustible') }}
                  <v-icon
                    small
                    right
                    :color="orderByCombusible === null ? '' : 'primary'"
                  >
                    {{ orderByCombusible !== null ? (orderByCombusible ? icons.mdiArrowUp : icons.mdiArrowDown) : null }}
                  </v-icon>
                </v-btn>
              </th>-->
              <th
                class="text-uppercase"
                width="60%"
              >
                {{ $t('lbl.name') }}
              </th>
              <th class="text-uppercase">
                {{ $t('menu.typeDestination') }}
              </th>
              <th class="text-uppercase">
                {{ $t('menu.operador') }}
              </th>
              <th
                v-if="permisos.includes('hotel:edit') || permisos.includes('hotel:list')"
                width="150px"
                class="text-uppercase"
                style="text-align: center;"
              >
                {{ $t('lbl.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(iten, index) in items"
              :key="index"
            >
              <td>
                <NameHotel :hotel="iten" />
              </td>
              <td>
                <span v-if="iten.tipo_destino_id">
                  {{ iten.tipo_destino.name }}
                </span>
              </td>
              <td>
                <span v-if="iten.operador_id">
                  {{ iten.operador.name }}
                </span>
                <!--<span v-if="iten.marca_asociada_id">
                  <br />
                  {{ iten.marca_asociada.name }}
                </span>-->
              </td>
              <!--<td>
                <v-row>
                  <v-col
                    cols="12"
                    md="9"
                    sm="9"
                  >
                    {{ iten.marca.name }}
                  </v-col>
                </v-row>
              </td>
              <td>{{ iten.modelo_id !== null ? iten.modelo.name : '' }}</td>
              <td>{{ iten.transmision_id !== null ? iten.transmision.name : '' }}</td>
              <td>{{ iten.tipo_auto_id !== null ? iten.tipo_auto.name : '' }}</td>
              <td>{{ iten.combustible_id !== null ? iten.combustible.name : '' }}</td>
              <td>
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiEye }}</v-icon>
                    </v-btn>
                  </template>
                  <span>
                    <v-card
                      class="mx-auto"
                    >
                      <v-card-text>
                        <div class="text--primary">
                          <span>{{ $t('lbl.cantPuertas') }}: {{ iten.cant_puertas }}</span><br>
                          <span>{{ $t('lbl.cantPaxs') }}: {{ iten.cant_paxs }}</span><br>
                          <span>{{ $t('lbl.cantMaletas') }} ({{ $t('lbl.small') }}): {{ iten.cant_maletas_small }}</span><br>
                          <span>{{ $t('lbl.cantMaletas') }} ({{ $t('lbl.big') }}): {{ iten.cant_maletas_big }}</span><br>
                          <span>{{ $t('lbl.capacityComb') }}: {{ iten.capacidad_combustible }} {{ iten.um_combustible }}</span><br>
                          <span>{{ $t('lbl.consumoComb') }}: {{ iten.consumo_combustible }} {{ iten.um_combustible !== undefined ? `km/${iten.um_combustible}` : '' }}</span><br>
                          <span>{{ $t('lbl.motor') }}: {{ iten.motor }}</span><br>

                          <br>
                          <strong>
                            {{ $t('menu.caracteristicas') }}
                          </strong><br>
                          <span
                            v-for="(cara, i) in caracteristicas"
                            :key="i"
                          >
                            <span
                              v-for="(caraIten, j) in iten.caracteristicas_id"
                              :key="`j${j}`"
                            >
                              <span v-if="caraIten === cara.id">{{ cara.name }}<br></span>
                            </span>
                          </span>
                        </div>
                      </v-card-text>
                    </v-card>
                  </span>
                </v-tooltip>
              </td>-->
              <td
                v-if="
                  permisos.includes('hotel:edit') || permisos.includes('hotel:list') || permisos.includes('hotel:destroy')
                "
                style="text-align: center;"
              >
                <v-menu
                  bottom
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item
                      v-if="permisos.includes('hotel:edit')"
                      link
                      @click="editItem(iten)"
                    >
                      <v-list-item-title>
                        <v-btn icon>
                          <v-icon>{{ icons.mdiSquareEditOutline }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.edit') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="permisos.includes('hotel:list') || permisos.includes('*')"
                      link
                      @click="showItem(iten)"
                    >
                      <v-list-item-title>
                        <v-btn icon>
                          <v-icon>{{ icons.mdiEye }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.see') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="permisos.includes('hotel:destroy')"
                      link
                      @click="deleteItem(iten)"
                    >
                      <v-list-item-title>
                        <v-btn icon>
                          <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                        </v-btn>
                        <span>{{ $t('btn.delete') }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>

      <v-card-text class="pt-2">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-end"
          >
            <v-pagination
              v-model="pagination.current"
              :length="pagination.total"
              total-visible="5"
              @input="onPageChange"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <v-skeleton-loader
      v-else
      class="mx-auto"
      type="table-tbody"
    ></v-skeleton-loader>

    <!-- MOSTRAR DELETE -->
    <v-dialog
      v-model="isDialogVisibleDelete"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.delete')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{ $t('msg.deleteHotel', { n: item.name }) }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isDialogVisibleDelete = !isDialogVisibleDelete"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="loading"
            @click="confirmDelete()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyePlus,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiFormatListText,
  mdiEraserVariant,
  mdiArrowUp,
  mdiArrowDown,
  mdiMenuDown,
  mdiMenuRight,
  mdiEye,
  mdiUpload,
  mdiCloudUpload,
  mdiDotsVertical,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'

import NameHotel from '../utils/NameHotel.vue'
import Filtros from '../utils/Filtros.vue'

export default {
  components: {
    NameHotel,
    Filtros,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: false,
      isLoadingFilters: false,
      isLoadingInfo: true,
      isLoadingDestinos: true,
      isLoadingTipoDestinos: true,
      isLoadingCategories: true,
      isLoadingOperadors: true,
      isLoadingMarcas: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      search: '',
      orderByName: null,
      orderByMarca: null,
      orderByModelo: null,
      orderByTransmision: null,
      orderByTypeAuto: null,
      orderByCombusible: null,
      items: [],
      pagination: {
        current: 1,
        total: 0,
      },
      totalItems: 0,
      totalF: 0,
      totalNotPublic: 0,
      itemsPerPage: 10,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyePlus,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiFormatListText,
        mdiEraserVariant,
        mdiArrowUp,
        mdiArrowDown,
        mdiMenuDown,
        mdiMenuRight,
        mdiEye,
        mdiUpload,
        mdiCloudUpload,
        mdiDotsVertical,
      },
      isDialogVisible: false,
      model: {},
      transmisiones: [],
      marcas: [],
      combustibles: [],
      semanas: [],
      itemsOrigen: [],
      searchOrigen: null,
      destinos: [],
      itemsDestino: [],
      searchDestino: null,
      itemsType: [],
      searchType: null,
      loading: false,
      tiposDestino: [],
      categories: [],

      showRow: false,
      indexRow: -1,

      item: {},
      itemId: null,
      isDialogVisibleDelete: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),

      itemsOperadores: [],
      allOperadores: [],
      searchOperador: null,

      itemsMarcas: [],
      allMarcas: [],
      searchMarcas: null,
      showInfoPendient: false,

      totalInfoPendiente: 0,
      totalSinOperador: 0,
      totalSinMarca: 0,
      totalSinFotos: 0,
      totalSinTagFotos: 0,
      totalSinTagPrincipal: 0,
      totalSinDireccion: 0,
      totalSinLocalidad: 0,
      totalSinCrop: 0,
      totalSinContact: 0,
      user: {},
      offline: false,
    }
  },
  computed: {
    ...mapState({
      hotelsProductCache: state => state.app.hotelsProductCache,
      hotelsProductFiltersCache: state => state.app.hotelsProductFiltersCache,
    }),
  },
  watch: {
    searchDestino(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
    searchOperador(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOperador(val.toLowerCase())
      } else {
        this.itemsOperadores = []
      }
    },
    searchMarcas(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterMarcas(val.toLowerCase())
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.items = this.hotelsProductCache
    this.totalNotPublic = this.hotelsProductFiltersCache.totalNotPublic
    this.totalInfoPendiente = this.hotelsProductFiltersCache.totalInfoPendiente
    this.totalSinOperador = this.hotelsProductFiltersCache.totalSinOperador
    this.totalSinMarca = this.hotelsProductFiltersCache.totalSinMarca
    this.totalSinFotos = this.hotelsProductFiltersCache.totalSinFotos
    this.totalSinTagFotos = this.hotelsProductFiltersCache.totalSinTagFotos
    this.totalSinTagPrincipal = this.hotelsProductFiltersCache.totalSinTagPrincipal
    this.totalSinDireccion = this.hotelsProductFiltersCache.totalSinDireccion
    this.totalSinLocalidad = this.hotelsProductFiltersCache.totalSinLocalidad
    this.totalSinCrop = this.hotelsProductFiltersCache.totalSinCrop
    this.totalSinContact = this.hotelsProductFiltersCache.totalSinContact

    this.profile()
    this.fetchDataFromEndpoints()

    // this.getTipoDestino()
    // this.getCategorias()
    // this.getDestinos()
    // this.getOperadores()
    // this.getMarcasAsociadas()
  },
  methods: {
    ...mapMutations(['updateAddressHotel', 'updateImgHotels']),
    refresh(json) {
      this.model = json
      this.pagination = {
        current: 1,
        total: 0,
      }
      this.load()
    },
    profile() {
      this.user = JSON.parse(localStorage.getItem('profile'))
      if (this.user.company_id) {
        if (this.user.company) {
          if (this.user.company.offline) {
            this.offline = this.user.company.offline
          }
        }
      }

      /*
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          }
          this.user = response.data.data
          if (this.user.company_id) {
            if (this.user.company) {
              if (this.user.company.offline) {
                this.offline = this.user.company.offline
              }
            }
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.load()
          this.loadAll()
        }) */
    },
    async fetchDataFromEndpoints() {
      this.loading = true
      const jsonItems = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        // eslint-disable-next-line no-nested-ternary
        orderByName: this.orderByName !== null ? (this.orderByName ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByMarca: this.orderByMarca !== null ? (this.orderByMarca ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByModelo: this.orderByModelo !== null ? (this.orderByModelo ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTransmision: this.orderByTransmision !== null ? (this.orderByTransmision ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTypeAuto: this.orderByTypeAuto !== null ? (this.orderByTypeAuto ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByCombusible: this.orderByCombusible !== null ? (this.orderByCombusible ? 11 : 22) : null,
        perfil_slug: localStorage.getItem('perfil'),
      }

      const jsonAll = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: localStorage.getItem('perfil'),
      }
      try {
        const [
          resItems,
          resAll,
          resDestinos,
          resTipoDestino,
          resCategories,
          resOperadors,
          resMarcaAsociada,
        ] = await Promise.all([
          this.axios
            .post('hotels/list', jsonItems, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .post('hotels/list', jsonAll, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get('destinos-hotels-filter-product', {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
          this.axios
            .get(`nom_marca_asociada_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            }),
        ])

        // eslint-disable-next-line no-empty
        if (resItems.data.success === false) { } else {
          this.items = resItems.data.data
          this.pagination.current = resItems.data.meta.current_page
          this.pagination.total = resItems.data.meta.last_page

          this.totalF = resItems.data.meta.total

          this.allFilter()
        }

        // eslint-disable-next-line no-empty
        if (resAll.data.success === false) { } else {
          this.totalItems = resAll.data.meta.total
        }

        this.destinos = resDestinos.data.data.data
        this.tiposDestino = resTipoDestino.data.data
        this.categories = resCategories.data.data
        this.allOperadores = resOperadors.data.data
        this.allMarcas = resMarcaAsociada.data.data
      } catch (err) {
        console.error('Error fetching data:', err)
      } finally {
        this.isLoading = false
        this.isLoadingDestinos = false
        this.isLoadingTipoDestinos = false
        this.isLoadingCategories = false
        this.isLoadingOperadors = false
        this.isLoadingMarcas = false

        this.isLoadingInfo = false
      }
    },
    async load() {
      this.loading = true

      // this.axios.get(`flights?page=${this.pagination.current}&per_page=${this.itemsPerPage}&search=${this.model}&perfil_slug=${localStorage.getItem('perfil')}`, {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        search: this.model,
        // eslint-disable-next-line no-nested-ternary
        orderByName: this.orderByName !== null ? (this.orderByName ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByMarca: this.orderByMarca !== null ? (this.orderByMarca ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByModelo: this.orderByModelo !== null ? (this.orderByModelo ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTransmision: this.orderByTransmision !== null ? (this.orderByTransmision ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByTypeAuto: this.orderByTypeAuto !== null ? (this.orderByTypeAuto ? 11 : 22) : null,
        // eslint-disable-next-line no-nested-ternary
        orderByCombusible: this.orderByCombusible !== null ? (this.orderByCombusible ? 11 : 22) : null,
        perfil_slug: localStorage.getItem('perfil'),
      }

      await this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.items = res.data.data
            this.pagination.current = res.data.meta.current_page
            this.pagination.total = res.data.meta.last_page

            this.totalF = res.data.meta.total
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.isLoading = false
          this.allFilter()

          // PARA Q NAZCA EL MENU IZQ OCULTO
          this.menuIsVerticalNavMini = true
        })
    },
    async allFilter() {
      const json = {
        search: this.model,
      }

      await this.axios
        .post('hotels/all-filter', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            /* this.$toast.error(this.$t('msg.noAutorice'))
          this.$store.commit('logout') */
          } else {
            this.totalNotPublic = res.data.notPublic
            this.totalInfoPendiente = res.data.totalInfoPendiente
            this.totalSinOperador = res.data.totalSinOperador
            this.totalSinMarca = res.data.totalSinMarca
            this.totalSinFotos = res.data.totalSinFotos
            this.totalSinTagFotos = res.data.totalSinTagFotos
            this.totalSinTagPrincipal = res.data.totalSinTagPrincipal
            this.totalSinDireccion = res.data.totalSinDireccion
            this.totalSinLocalidad = res.data.totalSinLocalidad
            this.totalSinCrop = res.data.totalSinCrop
            this.totalSinContact = res.data.totalSinContact
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loading = false
          this.isLoadingFilters = false

          // this.loadAll()
        })
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.itemsDestino = this.destinos.filter(e => e.toLowerCase())
      }
    },
    filterOperador(v) {
      this.itemsOperadores = []
      if (v === '') {
        this.itemsOperadores = []
      } else {
        this.itemsOperadores = this.allOperadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
    },
    orderBy(attr) {
      if (attr === 'name') {
        if (this.orderByName === null) {
          this.orderByName = true
        } else {
          this.orderByName = !this.orderByName
        }

        // APAGAR
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'marca') {
        if (this.orderByMarca === null) {
          this.orderByMarca = true
        } else {
          this.orderByMarca = !this.orderByMarca
        }

        // APAGAR
        this.orderByName = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'modelo') {
        if (this.orderByModelo === null) {
          this.orderByModelo = true
        } else {
          this.orderByModelo = !this.orderByModelo
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'trasnmision') {
        if (this.orderByTransmision === null) {
          this.orderByTransmision = true
        } else {
          this.orderByTransmision = !this.orderByTransmision
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTypeAuto = null
        this.orderByCombusible = null
      } else if (attr === 'typeCar') {
        if (this.orderByTypeAuto === null) {
          this.orderByTypeAuto = true
        } else {
          this.orderByTypeAuto = !this.orderByTypeAuto
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByCombusible = null
      } else if (attr === 'combustible') {
        if (this.orderByCombusible === null) {
          this.orderByCombusible = true
        } else {
          this.orderByCombusible = !this.orderByCombusible
        }

        // APAGAR
        this.orderByName = null
        this.orderByMarca = null
        this.orderByModelo = null
        this.orderByTransmision = null
        this.orderByTypeAuto = null
      }

      this.load()
    },
    infoPendient() {
      this.showInfoPendient = !this.showInfoPendient
    },
    changeNotPublic() {
      if (this.model.not_public) {
        this.model.not_public = null
      } else {
        this.model.not_public = 11
      }
      this.load()
    },
    changeSinOperador() {
      if (this.model.sin_operador) {
        this.model.sin_operador = null
      } else {
        this.model.sin_operador = 11
      }
      this.load()
    },
    changeSinMarca() {
      if (this.model.sin_marca) {
        this.model.sin_marca = null
      } else {
        this.model.sin_marca = 11
      }
      this.load()
    },
    changeSinFotos() {
      if (this.model.sin_fotos) {
        this.model.sin_fotos = null
      } else {
        this.model.sin_fotos = 11
      }
      this.load()
    },
    changeSinTagFotos() {
      if (this.model.sin_tag_fotos) {
        this.model.sin_tag_fotos = null
      } else {
        this.model.sin_tag_fotos = 11
      }
      this.load()
    },
    changeSinTagPrincipal() {
      if (this.model.sin_tag_principal) {
        this.model.sin_tag_principal = null
      } else {
        this.model.sin_tag_principal = 11
      }
      this.load()
    },
    changeSinDireccion() {
      if (this.model.sin_direccion) {
        this.model.sin_direccion = null
      } else {
        this.model.sin_direccion = 11
      }
      this.load()
    },
    changeSinLocalidad() {
      if (this.model.sin_localidad) {
        this.model.sin_localidad = null
      } else {
        this.model.sin_localidad = 11
      }
      this.load()
    },
    changeSinCrop() {
      if (this.model.sin_crop) {
        this.model.sin_crop = null
      } else {
        this.model.sin_crop = 11
      }
      this.load()
    },
    changeSinContact() {
      if (this.model.sin_contact) {
        this.model.sin_contact = null
      } else {
        this.model.sin_contact = 11
      }
      this.load()
    },
    setOperadores() {
      this.allMarcas = []
      if (this.allOperadores.filter(e => e.id === this.model.operador_id)[0].marcas.length > 0) {
        this.allMarcas = this.allOperadores.filter(e => e.id === this.model.operador_id)[0].marcas
        this.itemsMarcas = this.allMarcas
      }
    },
    onPageChange() {
      this.load()
    },
    closeDialog() {
      this.isDialogVisible = !this.isDialogVisible
    },
    newItem() {
      localStorage.removeItem('contrato-hotels-show')
      localStorage.removeItem('hotels-id')
      localStorage.removeItem('hotels-clone')
      localStorage.removeItem('hotels-show')
      this.updateAddressHotel({
        country: null,
        state: null,
        city: null,
        direccion: null,
      })
      this.updateImgHotels([])
      this.$router.push({ name: 'hotels-update' })
    },
    uploadLote() {
      localStorage.removeItem('hotels-id')
      localStorage.removeItem('hotels-clone')
      localStorage.removeItem('hotels-show')
      this.$router.push({ name: 'hotels-upload-lote' })
    },
    showItem(iten) {
      localStorage.removeItem('contrato-hotels-show')
      localStorage.setItem('hotels-show', true)
      localStorage.setItem('hotels-id', iten.id)
      this.$router.push({ name: 'hotels-update' })
    },
    editItem(iten) {
      localStorage.removeItem('hotels-show')
      localStorage.removeItem('contrato-hotels-show')
      localStorage.setItem('hotels-id', iten.id)
      this.updateImgHotels([])
      this.$router.push({ name: 'hotels-update' })
    },
    cloneItem(iten) {
      localStorage.setItem('hotels-id', iten.id)
      localStorage.setItem('hotels-clone', true)
      this.$router.push({ name: 'hotels-update' })
    },
    exportar() {
      localStorage.setItem('entity-export', 'hotels')
      this.$router.push({ name: 'hotels-export' })
    },
    deleteItem(iten) {
      this.isDialogVisibleDelete = true
      this.item = iten
      this.itemId = iten.id
    },
    async confirmDelete() {
      await this.axios
        .delete(`hotels/${this.itemId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            if (res.data.data.status === 401) {
              // this.$toast.error(this.$t('msg.noAutorice'))
            } else if (res.data.data.status === 402) {
              this.$toast.error(this.$t('msg.errorDeleteHotel', { n: this.item.name }))
            }
          } else {
            this.load()
            this.$toast.success(this.$t('msg.infoSuccess'))
          }
        })
        .finally(() => this.reseat())
    },
    reseat() {
      this.isDialogVisibleDelete = false
      this.itemId = null
      this.loading = false
      this.load()
    },

    clearSearch() {
      this.model = {}
      this.showInfoPendient = false
      this.itemsMarcas = []
      this.itemsModelos = []
      this.itemsTypeAuto = []
      this.load()
    },

    changeMarca() {
      if (this.model.marca_id) {
        const marc = this.marcas.filter(a => a.id === this.model.marca_id)[0]
        this.modelos = marc.modelos
      } else {
        this.modelos = []
      }
    },

    /*
    getDestinos() {
      this.axios
        .get('destinos-hotels-filter-product', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.destinos = res.data.data.data
        })
        .finally(() => {
          this.isLoadingDestinos = false
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.tiposDestino = res.data.data
        })
        .finally(() => {
          this.isLoadingTipoDestinos = false
        })
    },
    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data
        })
        .finally(() => {
          this.isLoadingCategories = false
        })
    },
    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allOperadores = res.data.data
        })
        .finally(() => {
          this.isLoadingOperadors = false
        })
    },
    getMarcasAsociadas() {
      this.axios
        .get(`nom_marca_asociada_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allMarcas = res.data.data
        })
        .finally(() => {
          this.isLoadingMarcas = false
        })
    },
    loadAll() {
      const json = {
        page: this.pagination.current,
        per_page: this.itemsPerPage,
        perfil_slug: localStorage.getItem('perfil'),
      }
      this.axios
        .post('hotels/list', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === false) {
          } else {
            this.totalItems = res.data.meta.total
          }
        })
    },
    */

    /* showItem(index) {
      if (this.indexRow === index) {
        this.showRow = !this.showRow
      } else if (!this.showRow) {
        this.showRow = !this.showRow
      }
      this.indexRow = index
    }, */
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}
</style>
